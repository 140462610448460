import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { BiSearch } from "react-icons/bi";
import { IoPersonOutline } from "react-icons/io5";
import { Stepper, Step } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Vendor } from "../../interfaces";
import axios from "axios";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";
import { Dialog } from "@material-tailwind/react";
import { Navigate } from "react-router-dom";

const VendorInfo = () => {
  const data: Vendor = useLocation().state;
  const navigate = useNavigate();

  const [VCountryState, setVCountryState] = useState<{
    country: string | undefined;
    state: string | undefined;
  }>({
    country: "",
    state: "",
  });
  //   const [data, setData] = useState<Vendor>(vdata)
  const [niche, setNiche] = useState({ niche: "", subNiche: "" });
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  const [declineModal, setDeclineModal] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [declineLoading, setDeclineLoading] = useState(false);

  useEffect(() => {
    // console.log(data);
    // if(vdata === null){
    //     navigate("/vendor")
    // }
    if (data) {
      axios.get("/api/web/countries").then((res) => {
        //   setCountries((prev) => (prev = res.data.data));
        setVCountryState(
          (prev) =>
            (prev = {
              ...prev,
              country: res.data.data.find(
                (c: { id: number; name: string }) => c.id === data.country_id
              )?.name,
            })
        );
      });
      axios.get("/api/web/states").then((res) => {
        //   setStates((prev) => (prev = res.data.data));
        setVCountryState(
          (prev) =>
            (prev = {
              ...prev,
              state: res.data.data.find(
                (c: { id: number; name: string }) => c.id === data.state_id
              )?.name,
            })
        );
      });
      axios.get(`/api/web/niches/${data.niche_id}`).then((res) => {
        // console.log(res.data.data)
        setNiche(
          (prev) =>
            (prev = {
              niche: res.data.data.name,
              subNiche: res.data.data.sub_niches.find(
                (n: { id: number; name: string }) => n.id === data.sub_niche_id
              ).name,
            })
        );
      });
    } else {
        navigate("/vendor")
    }
  }, []);

  const approveVendor = () => {
    const stat = {
      status: "approved",
    };
    setApproveLoading(true);
    axios
      .post(`/api/admin/vendors/process/${data.id}`, stat)
      .then((res) => {
        // console.log(res.data)
        setApproveLoading(false);
        toast("Success! Vendor Approved", {
          type: toast.TYPE.SUCCESS,
        });
        navigate("/vendor");
      })
      .catch((err) => {
        console.log(err);
        setApproveLoading(false);
        toast("An error occured, try again!", {
          type: toast.TYPE.ERROR,
        });
      });
  };
  const dismissDecline = () => {
    setDeclineModal(!declineModal);
  };
  const declineVendor = () => {
    if (!declineReason) {
      toast("Reason for decline is required!", { type: toast.TYPE.ERROR });
      return;
    }
    const dec = { status: "declined", reason: declineReason };
    setDeclineLoading(true);
    axios
      .post(`/api/admin/vendors/process/${data.id}`, dec)
      .then((res) => {
        setDeclineLoading(false);
        toast(`Vendor ${data.id} Declined!`, {
          type: toast.TYPE.INFO,
        });
        navigate("/vendor");
      })
      .catch((err) => {
        console.log(err);
        setDeclineLoading(false);
        toast("An error occured, try again!", {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);
  if(!data){
    navigate("/vendor")
    return<></>
  }

  return (
    <div>
      <Header title="Vendor" />

      <div className="px-6 sm:px-11 pb-11 ">
        <div className="relative">
          <div className="flex flex-col sm:flex-row justify-between items-center ">
            <p className="py-4 font-bold mb-0 text-xl">Informations</p>
          </div>
          <div className="pb-6">
            {data.account_type === "Individual" && (
              <Stepper
                activeStep={activeStep}
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
                lineClassName="bg-[#CFD4D9] h-1"
                activeLineClassName="bg-cs-blue"
              >
                <Step
                  className="bg-[#CFD4D9] text-white w-10 h-10 flex justify-center items-center"
                  activeClassName="ring-0 !bg-cs-blue text-white"
                  completedClassName="w-10 h-10 bg-cs-blue"
                  onClick={() => setActiveStep(0)}
                >
                  1
                </Step>
                <Step
                  className="bg-[#CFD4D9] text-white w-10 h-10 flex justify-center items-center"
                  activeClassName="ring-0 !bg-cs-blue text-white"
                  completedClassName="w-10 h-10 bg-cs-blue"
                  onClick={() => setActiveStep(1)}
                >
                  2
                </Step>
                <Step
                  className="bg-[#CFD4D9] text-white w-10 h-10 flex justify-center items-center"
                  activeClassName="ring-0 !bg-cs-blue text-white"
                  completedClassName="w-10 h-10 bg-cs-blue"
                  onClick={() => setActiveStep(2)}
                >
                  3
                </Step>
              </Stepper>
            )}
            {data.account_type === "Business" && (
              <Stepper
                activeStep={activeStep}
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
                lineClassName="bg-[#CFD4D9] h-1"
                activeLineClassName="bg-cs-blue"
              >
                <Step
                  className="bg-[#CFD4D9] text-white w-10 h-10 flex justify-center items-center"
                  activeClassName="ring-0 !bg-cs-blue text-white"
                  completedClassName="w-10 h-10 bg-cs-blue"
                  onClick={() => setActiveStep(0)}
                >
                  1
                </Step>
                <Step
                  className="bg-[#CFD4D9] text-white w-10 h-10 flex justify-center items-center"
                  activeClassName="ring-0 !bg-cs-blue text-white"
                  completedClassName="w-10 h-10 bg-cs-blue"
                  onClick={() => setActiveStep(1)}
                >
                  2
                </Step>
                <Step
                  className="bg-[#CFD4D9] text-white w-10 h-10 flex justify-center items-center"
                  activeClassName="ring-0 !bg-cs-blue text-white"
                  completedClassName="w-10 h-10 bg-cs-blue"
                  onClick={() => setActiveStep(2)}
                >
                  3
                </Step>
                <Step
                  className="bg-[#CFD4D9] text-white w-10 h-10 flex justify-center items-center"
                  activeClassName="ring-0 !bg-cs-blue text-white"
                  completedClassName="w-10 h-10 bg-cs-blue"
                  onClick={() => setActiveStep(3)}
                >
                  4
                </Step>
              </Stepper>
            )}
          </div>
          {/* Type */}
          <div className="py-6 flex justify-center gap-24 sm:gap-52">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                name="account_type"
                className="accent-cs-blue"
                readOnly
                checked={data.account_type === "Individual"}
              />
              <label htmlFor="account_type">Individual</label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="radio"
                name="account_type"
                readOnly
                checked={data.account_type === "Business"}
                className="accent-cs-blue"
              />
              <label htmlFor="account_type">Business</label>
            </div>
          </div>

          {/* Info 1 */}
          {activeStep === 0 && data.account_type === "Business" && (
            <div className="flex flex-col items-center">
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Business Name</label>
                <div className="relative inline-block">
                  <input
                    name="full_name"
                    className="min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-8 py-1 pe-6"
                    type="text"
                    value={data.business_name}
                    readOnly
                  />
                  <div className="absolute left-0 top-0 h-full w-[10%] sm:w-[6%] flex justify-center items-center">
                    <IoPersonOutline className="hover:cursor-pointer" />
                  </div>
                </div>
              </div>
              <div className="pb-4 min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] grid sm:grid-cols-2 gap-2">
                <div className="flex flex-col">
                  <label htmlFor="is_business_registered">
                    Is Business Registered
                  </label>
                  <div className="relative inline-block">
                    <input
                      name="is_business_registered"
                      className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-2 py-1 pe-6"
                      type="text"
                      value={data.is_business_registered.toString()}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="full_name">Registeration Code</label>
                  <div className="relative inline-block">
                    <input
                      name="full_name"
                      className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-2 py-1 pe-6"
                      type="text"
                      value={data.registration_code}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Business Document</label>
                <div className="relative inline-block">
                  <div className="h-[300px] min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] rounded-lg">
                    {data.media[1]?.mime_type === "application/pdf" ? (
                      <iframe
                        src={`https://docs.google.com/gview?url=${data.media[1]?.original_url}&embedded=true`}
                        title="busDoc-pdf-viewer"
                        className="w-[100%] h-[100%]"
                      ></iframe>
                    ) : (
                      <img
                        className="h-full mx-auto"
                        src={data.media[1]?.original_url}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex flex-col pb-4">
                  <label htmlFor="full_name">Business Role</label>
                  <div className="relative inline-block">
                    <input
                      name="full_name"
                      className="min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-2 py-1 pe-6"
                      type="text"
                      value={data.business_role}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Info 2 */}
          {((activeStep === 1 && data.account_type === "Business") ||
            (data.account_type === "Individual" && activeStep === 0)) && (
            <div className="flex flex-col items-center">
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Full Name</label>
                <div className="relative inline-block">
                  <input
                    name="full_name"
                    className="min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-8 py-1 pe-6"
                    type="text"
                    value={data.full_name}
                    readOnly
                  />
                  <div className="absolute left-0 top-0 h-full w-[10%] sm:w-[6%] flex justify-center items-center">
                    <IoPersonOutline className="hover:cursor-pointer" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Email</label>
                <div className="relative inline-block">
                  <input
                    name="full_name"
                    className="min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-8 py-1 pe-6"
                    type="text"
                    value={data.email}
                    readOnly
                  />
                  <div className="absolute left-0 top-0 h-full w-[10%] sm:w-[6%] flex justify-center items-center">
                    <IoPersonOutline className="hover:cursor-pointer" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Phone Number</label>
                <div className="relative inline-block">
                  <input
                    name="full_name"
                    className="min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-8 py-1 pe-6"
                    type="text"
                    value={data.phone_no}
                    readOnly
                  />
                  <div className="absolute left-0 top-0 h-full w-[10%] sm:w-[6%] flex justify-center items-center">
                    <IoPersonOutline className="hover:cursor-pointer" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Means of Id</label>
                <div className="relative inline-block">
                  <div className="h-[300px] min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] rounded-lg">
                    {data.media[0]?.mime_type === "application/pdf" ? (
                      <iframe
                        src={`https://docs.google.com/gview?url=${data.media[0]?.original_url}&embedded=true`}
                        title="busDoc-pdf-viewer"
                        className="w-[100%] h-[100%]"
                      ></iframe>
                    ) : (
                      <img
                        className="h-full mx-auto"
                        src={data.media[0]?.original_url}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Info 3 */}
          {((activeStep === 2 && data.account_type === "Business") ||
            (data.account_type === "Individual" && activeStep === 1)) && (
            <div className="flex flex-col items-center">
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Logo</label>
                <div className="relative inline-block">
                  <div className="h-[200px] min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] rounded-lg">
                    {data.media[data.account_type === "Business" ? 2 : 1]
                      ?.mime_type === "application/pdf" ? (
                      <iframe
                        src={`https://docs.google.com/gview?url=${
                          data.media[data.account_type === "Business" ? 2 : 1]
                            ?.original_url
                        }&embedded=true`}
                        title="pdf-viewer"
                        className="w-full h-full"
                      ></iframe>
                    ) : (
                      <img
                        className="h-full mx-auto"
                        src={
                          data.media[data.account_type === "Business" ? 2 : 1]
                            ?.original_url
                        }
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="pb-4 min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] grid sm:grid-cols-2 gap-2">
                <div className="flex flex-col">
                  <label htmlFor="full_name">Country</label>
                  <div className="relative inline-block">
                    <input
                      name="full_name"
                      className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-2 py-1 pe-6"
                      type="text"
                      placeholder="loading..."
                      value={VCountryState.country}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="full_name">State</label>
                  <div className="relative inline-block">
                    <input
                      name="full_name"
                      className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-2 py-1 pe-6"
                      type="text"
                      placeholder="loading..."
                      value={VCountryState.state}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Address</label>
                <div className="relative inline-block">
                  <input
                    name="full_name"
                    className="min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg  ps-2 py-1 pe-6"
                    type="text"
                    value={data.address}
                    readOnly
                  />
                </div>
              </div>
            </div>
          )}
          {/* Info 4 */}
          {((activeStep === 3 && data.account_type === "Business") ||
            (data.account_type === "Individual" && activeStep === 2)) && (
            <div className="flex flex-col items-center">
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Other Documents</label>
                <div className="relative inline-block">
                  <div className="h-[200px] min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] rounded-lg">
                    {data.media[2]?.mime_type === "application/pdf" ? (
                      <iframe
                        src={`https://docs.google.com/gview?url=${data.media[2]?.original_url}&embedded=true`}
                        title="pdf-viewer"
                        className="w-[100%] h-[100%]"
                      ></iframe>
                    ) : (
                      <img
                        className="h-full mx-auto"
                        src={data.media[2]?.original_url}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="pb-4 min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] grid sm:grid-cols-2 gap-2">
                <div className="flex flex-col">
                  <label htmlFor="full_name">Niche</label>
                  <div className="relative inline-block">
                    <input
                      name="full_name"
                      className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-2 py-1 pe-6"
                      type="text"
                      value={niche.niche}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="full_name">Sub</label>
                  <div className="relative inline-block">
                    <input
                      name="full_name"
                      className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-2 py-1 pe-6"
                      type="text"
                      value={niche.subNiche}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col pb-4">
                <label htmlFor="full_name">Description</label>
                <div className="relative inline-block">
                  <textarea
                    name="full_name"
                    className="min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg p-4 py-1 pe-6"
                    cols={6}
                    value={data.description}
                    readOnly
                  />
                </div>
              </div>
              <div className="min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px]">
                <p className="text-cs-blue font-bold">Payout Information</p>
                <p className="text-[#848383] text-sm">
                  All sales payments will be sent to this account details
                </p>
              </div>
              <div className="pb-4 min-w-[250px] sm:w-[390px] md:w-[450px] lg:w-[590px] grid sm:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label htmlFor="full_name">Bank Name</label>
                  <div className="relative inline-block">
                    <input
                      name="full_name"
                      className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-2 py-1 pe-6"
                      type="text"
                      placeholder="Bank"
                      value={data.bank_name}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="full_name">Account No</label>
                  <div className="relative inline-block">
                    <input
                      name="full_name"
                      placeholder="Account Number"
                      className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg ps-2 py-1 pe-6"
                      type="text"
                      value={data.bank_account_no}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <Dialog open={declineModal} handler={() => dismissDecline()}>
            <div className="modal max-w-[300px] bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
              <div className="flex flex-col items-center text-center px-4 gap-2">
                <p className="font-bold">Decline</p>
                <p>What is the reason for the decline?</p>
                <textarea
                  cols={15}
                  value={declineReason}
                  onChange={(e) => setDeclineReason(e.target.value)}
                  placeholder="Reason for vendor decline"
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded placeholder:text-center ps-3 p-1"
                />
                <button
                  disabled={declineLoading}
                  onClick={() => declineVendor()}
                  className="bg-cs-blue text-white rounded-lg px-6 py-1"
                >
                  {declineLoading ? (
                    <ImSpinner2 className="w-6 h-6 animate-spin" />
                  ) : (
                    "Ok"
                  )}
                </button>
              </div>
            </div>
          </Dialog>

          <div className="flex justify-center gap-6 pt-6">
            <button
              disabled={approveLoading}
              onClick={() => (!isLastStep ? handleNext() : approveVendor())}
              className="bg-cs-blue text-white rounded-lg px-6 py-1"
            >
              {approveLoading ? (
                <ImSpinner2 className="w-6 h-6 animate-spin" />
              ) : isLastStep ? (
                "Approve"
              ) : (
                "Next"
              )}
            </button>
            <button
              onClick={() => setDeclineModal(true)}
              className="border-cs-blue border-solid border-[1px] rounded-lg px-6 py-1"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorInfo;
