import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AppUser } from "../../interfaces";

const initialState: AppUser = {
  id: undefined,
  name: "",
  email: "",
  currency_id: undefined,
  email_verified_at: "",
  type: "",
  created_at: "",
  updated_at: ""
}

export const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        setAppUser: (state, action: PayloadAction<AppUser>)=>{
            localStorage.setItem("appUser", JSON.stringify(action.payload));
            return {
                ...action.payload,
            };
        },
    },
});

export const { setAppUser } = userSlice.actions;

export const selectUser = (state: RootState ) => state.user

export default userSlice.reducer;