import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { BiSearch, BiLeftArrow, BiRightArrow } from "react-icons/bi";
import TableLoader from "../../components/ContentLoaders/TableLoader";
import axios from "axios";
import { Vendor as VendorInterface } from "../../interfaces";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";

const Vendor = () => {
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(true);
  const [vendors, setVendors] = useState<VendorInterface[]>([]);
  const [links, setLinks] = useState({
    prev: null,
    next: null,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [nextLoading, setNextLoading] = useState(false);
  const getNextPage = (next: string | null) => {
    if (next) {
      setNextLoading(true);
      axios
        .get(next)
        .then((res) => {
          setNextLoading(false);
          setCurrentPage(res.data.data.current_page);
          setVendors((prev) => (prev = res.data.data.data));
          setLinks(
            (prev) =>
              (prev = {
                prev: res.data.data.prev_page_url,
                next: res.data.data.next_page_url,
              })
          );
        })
        .catch((err) => {
          setNextLoading(false);
          console.log(err);
        });
    }
  };
  const getPrevPage = (prev: string | null) => {
    if (prev) {
      setNextLoading(true);
      axios
        .get(prev)
        .then((res) => {
          setNextLoading(false);
          setCurrentPage(res.data.data.current_page);
          setVendors((state) => (state = res.data.data.data));
          setLinks(
            (state) =>
              (state = {
                prev: res.data.data.prev_page_url,
                next: res.data.data.next_page_url,
              })
          );
        })
        .catch((err) => {
          setNextLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getVendors();
  }, []);

  const getVendors = () => {
    axios
      .get("/api/admin/vendors")
      .then((res) => {
        console.log(res.data.data.data);
        setVendors((prev) => (prev = res.data.data.data));
        setLinks(
          (prev) =>
            (prev = {
              prev: res.data.data.prev_page_url,
              next: res.data.data.next_page_url,
            })
        );
        setCurrentPage((prev) => (prev = res.data.data.current_page));
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setDataLoading(false);
      });
  };
  type VendorType = "individual" | "business" | undefined;
  type VendorStatus = "approved" | "pending" | "declined" | "suspended" | undefined;
  const [filter, setFilter] = useState<{
    type: VendorType;
    status: VendorStatus;
  }>({
    status: undefined,
    type: undefined,
  });

  const [searchLoading, setSearchLoading] = useState(false);
  const runSearch = (text?: string) => {
    // if (text) {
    const param = {
      search: text,
      type: filter?.type,
      status: filter?.status,
    };
    setSearchLoading(true);
    setNextLoading(true);
    setLinks((prev) => (prev = { next: null, prev: null }));
    axios
      .get(`/api/admin/search/vendors`, { params: param })
      .then((res) => {
        setVendors((prev) => (prev = res.data.data.data));
        setSearchLoading(false);
        setNextLoading(false);
        setLinks(
          (prev) =>
            (prev = {
              prev: res.data.data.prev_page_url,
              next: res.data.data.next_page_url,
            })
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const handleStatusChange = (status: VendorStatus) => {
    setFilter((prev) => (prev = { ...prev, status: status }));
  };
  const handleTypeChange = (ty: VendorType) => {
    setFilter((prev) => (prev = { ...prev, type: ty }));
  };
  useEffect(() => {
    runSearch();
  }, [filter]);

  return (
    <div>
      <Header title="Vendor" />
      <div className="px-6 sm:px-11 pb-11 ">
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-center ">
            <p className="py-4 font-bold mb-0 text-xl">
              {filter.status
                ? filter.status.charAt(0).toUpperCase() + filter.status.slice(1)
                : "All"}{" "}
              Vendors
            </p>
            <div className="relative inline-block pb-2">
              <div className="inline font-bold text-cs-blue pe-3">
                <p
                  onClick={() => handleTypeChange(undefined)}
                  className={`inline px-2 mb-0  cursor-pointer ${
                    filter.type === undefined ? "underline" : ""
                  }`}
                >
                  All
                </p>
                <p
                  onClick={() => handleTypeChange("individual")}
                  className={`inline px-2 mb-0  cursor-pointer ${
                    filter.type === "individual" ? "underline" : ""
                  }`}
                >
                  Individual
                </p>
                <p
                  onClick={() => handleTypeChange("business")}
                  className={`inline px-2 mb-0  cursor-pointer ${
                    filter.type === "business" ? "underline" : ""
                  }`}
                >
                  Business
                </p>
              </div>

              <input
                className="sm:w-[200px] md:w-[250px] lg:w-[340px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 py-1 pe-6 placeholder:text-center"
                type="text"
                placeholder="search"
                onChange={(e) => runSearch(e.target.value)}
              />
              {/* <div className="absolute right-0 top-0 h-full w-[10%] flex justify-center items-center">
                <BiSearch className="hover:cursor-pointer" />
              </div> */}
            </div>
          </div>
          <div className="font-bold grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 pb-3">
            <div
              onClick={() => handleStatusChange(undefined)}
              className={`${
                filter.status === undefined
                  ? "text-white bg-cs-blue"
                  : "border-[#B0BABF] border-solid border-[1.5px]"
              } flex rounded-[10px] py-6 cursor-pointer`}
            >
              <p className="m-auto">All</p>
            </div>
            <div
              onClick={() => handleStatusChange("pending")}
              className={`${
                filter.status === "pending"
                  ? "text-white bg-cs-blue"
                  : "border-[#B0BABF] border-solid border-[1.5px]"
              } flex rounded-[10px] py-6 cursor-pointer`}
            >
              <p className="m-auto">Pending</p>
            </div>
            <div
              onClick={() => handleStatusChange("approved")}
              className={`${
                filter.status === "approved"
                  ? "text-white bg-cs-blue"
                  : "border-[#B0BABF] border-solid border-[1.5px]"
              } flex rounded-[10px] py-6 cursor-pointer`}
            >
              <p className="m-auto">Approved</p>
            </div>
            <div
              onClick={() => handleStatusChange("declined")}
              className={`${
                filter.status === "declined"
                  ? "text-white bg-cs-blue"
                  : "border-[#B0BABF] border-solid border-[1.5px]"
              } flex rounded-[10px] py-6 cursor-pointer`}
            >
              <p className="m-auto">Declined</p>
            </div>
            <div
              onClick={() => handleStatusChange("suspended")}
              className={`${
                filter.status === "suspended"
                  ? "text-white bg-cs-blue"
                  : "border-[#B0BABF] border-solid border-[1.5px]"
              } flex rounded-[10px] py-6 cursor-pointer`}
            >
              <p className="m-auto">Suspended</p>
            </div>
          </div>
          <div className="p-4 shadow-lg rounded border-solid border-[1px] border-[#ABB3B7]">
            <div className="overflow-auto lg:overflow-visible ">
              <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                <thead className="bg-cs-blue">
                  <tr className="text-white">
                    <th className="p-2">Id</th>
                    <th className="p-2">Type</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Status</th>
                    <th className="p-2">Products</th>
                    <th className="p-2">Orders</th>
                    <th className="p-2">Pending Orders</th>
                    <th className="p-2">Reviews</th>
                    <th className="p-2">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {dataLoading
                    ? Array(6)
                        .fill(1)
                        .map((a, row) => (
                          <tr key={row} className="bg-[#fff]">
                            {Array(9)
                              .fill(1)
                              .map((a, col) => (
                                <td key={col} className="">
                                  <div className="max-w-[90px] mx-auto">
                                    <TableLoader />
                                  </div>
                                </td>
                              ))}
                          </tr>
                        ))
                    : null}
                  {!dataLoading &&
                    vendors.map((vendor, idx) => {
                      const navPath =
                        vendor.status === "approved" ||
                        vendor.status === "suspended" ||
                        vendor.status === "declined"
                          ? `details/${vendor.id}`
                          : vendor.status === "pending"
                          ? `info/${vendor.id}`
                          : "/vendor";
                      return (
                        <tr
                          onClick={() =>
                            navigate(navPath, {
                              state: vendor,
                            })
                          }
                          key={idx}
                          className="cursor-pointer table-shadow"
                        >
                          <td className="p-2">{vendor.id}</td>
                          <td className="p-2">{vendor.account_type}</td>
                          <td className="p-2">{vendor.name}</td>
                          <td
                            className={
                              vendor.status === "approved"
                                ? "text-[#02A655]"
                                : vendor.status === "pending"
                                ? "text-[#FD7C04]"
                                : "text-[#C70303]"
                            }
                          >
                            <p>{vendor.status}</p>
                          </td>
                          <td className="p-2">0</td>
                          <td className="p-2">0</td>
                          <td className="p-2">0</td>
                          <td className="p-2">0</td>
                          <td className="p-2">
                            {new Date(vendor.created_at).toLocaleDateString()}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="bg-white w-full h-[60px] table-shadow rounded-lg flex items-center justify-center gap-6">
                <div
                  onClick={() => getPrevPage(links.prev)}
                  className={`${
                    links.prev
                      ? "hover:cursor-pointer text-gray-400"
                      : "hover:cursor-not-allowed"
                  }`}
                >
                  <BiLeftArrow
                    className={`${links.prev ? "text-black" : "text-gray-400"}`}
                  />
                </div>

                <div>
                  {nextLoading ? (
                    <MoonLoader size={20} />
                  ) : (
                    <p className="text-sm">
                      Page:{" "}
                      <span className="p-1 border-solid border-[1px] border-gray-400 rounded">
                        {currentPage}
                      </span>
                    </p>
                  )}
                </div>

                <div
                  onClick={() => getNextPage(links.next)}
                  className={`${
                    links.next
                      ? "hover:cursor-pointer text-gray-400"
                      : "hover:cursor-not-allowed"
                  }`}
                >
                  <BiRightArrow
                    className={`${links.next ? "text-black" : "text-gray-400"}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor;
