import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { Product } from "./ProductInterface";
import { useLocation, useNavigate } from "react-router-dom";
import { Rating } from "@material-tailwind/react";
import HTMLReactParser from "html-react-parser";
import { BiSolidStar } from "react-icons/bi";
import axios from "axios";
import { ImSpinner2 } from "react-icons/im";
import { Dialog } from "@material-tailwind/react";
import { toast } from "react-toastify";

const ProductInfo = () => {
  const navigate = useNavigate();
  const data: Product = useLocation().state;
  type tabs = "Description" | "Reviews";
  const [activeTab, setActiveTab] = useState<tabs>("Description");
  const [activeImage, setActiveImage] = useState(0);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [approveLoading, setApproveLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [declineDialog, setDeclineDialog] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [approveDialog, setApproveDialog] = useState(false);
  useEffect(() => {
    // setActiveTab("Description")
    setActiveImage(0);
    getCategory();
    getSubCategory();
  }, []);
  const getCategory = () => {
    axios
      .get(`/api/web/product-categories/${data.category.category.id}`)
      .then((res) => {
        // console.log(res.data.data.name)
        setCategory(res.data.data.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubCategory = () => {
    axios
      .get(`/api/web/product-subcategories/${data.category_id}`)
      .then((res) => {
        // console.log(res.data.data.name);
        setSubCategory(res.data.data.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveProduct = () => {
    setApproveLoading(true)
    axios
      .post(`/api/admin/products/process/${data.id}`, {
        status: "approved",
      })
      .then((res) => {
        console.log(res);
        setApproveLoading(false)
        toast(`Product ${data.id} Approved`, { type: toast.TYPE.SUCCESS });
        navigate("/product");
      })
      .catch((err) => {
        console.log(err);
        setApproveLoading(false)
        toast("An error occurred, try again.", {type: toast.TYPE.ERROR})
      });
  };
  const declineProduct = () => {
    if (!declineReason) {
      toast("Reason for decline is required!", { type: toast.TYPE.ERROR });
      return;
    }
    setDeclineLoading(true);
    const dData = {
      status: "declined",
      reason: declineReason,
    };
    axios
      .post(`/api/admin/products/process/${data.id}`, dData)
      .then((res) => {
        console.log(res);
        toast(`Product ${data.id} Declined`, { type: toast.TYPE.SUCCESS });
        setDeclineLoading(false);
        navigate('/product')
      })
      .catch((err) => {
        console.log(err);
        toast("An error occurred, try again.", { type: toast.TYPE.ERROR });
        setDeclineLoading(false);
      });
  };

  if (!data) {
    navigate("/product");
    return <></>;
  }

  return (
    <div>
      <Header title="Product" />
      <div className="px-6 sm:px-11 pt-6 pb-11">
        <div>
          <div className="flex justify-around border-solid border-b mb-4">
            <div
              onClick={() => setActiveTab("Description")}
              className={`w-[40%] text-center border-solid border-b cursor-pointer ${
                activeTab === "Description" && "border-cs-blue text-cs-blue"
              }`}
            >
              <p>Description</p>
            </div>
            <div
              onClick={() => setActiveTab("Reviews")}
              className={`w-[40%] text-center border-solid border-b cursor-pointer ${
                activeTab === "Reviews" && "border-cs-blue text-cs-blue"
              }`}
            >
              <p>Reviews</p>
            </div>
          </div>
          {activeTab === "Description" && (
            <div>
              <div className="w-full h-[400px] flex gap-4">
                <div className="w-[70%] h-full border-solid border">
                  <img
                    className="h-full mx-auto"
                    src={data.media[activeImage]?.original_url}
                    alt=""
                  />
                </div>
                <div className="w-[30%] grid grid-rows-4 gap-2">
                  {data.media.map((img, idx) => {
                    return (
                      <div
                        onClick={() => setActiveImage(idx)}
                        key={idx}
                        className={`cursor-pointer border-solid border ${
                          activeImage === idx && "border-cs-blue"
                        }`}
                      >
                        <img
                          className="h-full mx-auto"
                          src={img?.original_url}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col py-4 gap-1">
                <p className="font-bold">{data.name}</p>
                <p className="text-cs-blue font-bold">
                  ${data.price.toFixed(2)}
                </p>
                <div className="flex">
                  <Rating
                    className="flex"
                    ratedIcon={<BiSolidStar className="text-[#F7CE6C]" />}
                    unratedIcon={<BiSolidStar className="text-[#B0BABF]" />}
                    unratedColor="gray"
                    value={3}
                    readonly
                  />
                  <p className="ps-3">0</p>
                </div>
              </div>
              <div className="pb-4">
                <p className="font-bold">Description</p>
                <p className="text-[#848383] text-sm">
                  {HTMLReactParser(data.description)}
                </p>
              </div>
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Status</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.status}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Category</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {category}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">SKU</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.sku}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Sub Category</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {subCategory}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Is Enabled</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.is_enabled ? "true" : "false"}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Sale Price</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.sale_price}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Sale Start Date</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.sale_end_date}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Sale End Date</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.sale_end_date}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Width</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.width}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Height</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.height}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Quantity</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.quantity}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Part No.</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.part_no}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Location</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {data.location}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="text-[#848383]">Created At</p>
                  <p className="sm:max-w-[250px] ms-auto font-bold">
                    {new Date(data.created_at).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className="flex justify-center gap-4 py-11">
                <button
                  disabled={approveLoading || declineLoading}
                  onClick={() => setApproveDialog(true)}
                  className="bg-cs-blue text-white rounded-lg px-4 py-2"
                >
                  {approveLoading ? (
                    <ImSpinner2 className="w-6 h-6 animate-spin" />
                  ) : (
                    "Approve"
                  )}
                </button>
                <button
                  disabled={approveLoading || declineLoading}
                  onClick={() => setDeclineDialog(true)}
                  className="text-cs-blue border-solid border border-cs-blue px-4 py-2 rounded-lg"
                >
                  {declineLoading ? (
                    <ImSpinner2 className="w-6 h-6 animate-spin" />
                  ) : (
                    "Decine"
                  )}
                </button>
              </div>
            </div>
          )}
          {activeTab === "Reviews" && (
            <div className="py-4">
              <div className="w-full py-4 border-b-2 flex gap-4">
                <div className="">
                  <img
                    className="w-9 h-9 rounded-full"
                    src="/aire.jpg"
                    alt=""
                  />
                </div>
                <div className="w-full flex flex-col gap-4">
                  <div className="w-full flex justify-between">
                    <Rating
                      className="flex"
                      ratedIcon={<BiSolidStar className="text-[#F7CE6C]" />}
                      unratedIcon={<BiSolidStar className="text-[#B0BABF]" />}
                      unratedColor="gray"
                      value={3}
                      readonly
                    />
                    <div className="text-[#848383] flex gap-4">
                      <p>Product Name{`>>`}Sneakers</p>
                      <p>Category{`>>`}Fashion</p>
                      <p>Sub-Category{`>>`}Shoe</p>
                    </div>
                  </div>
                  <div className="w-full">
                    <p>
                      You made it so simple. My new site is so much faster and
                      easier to work with than my old site. I just choose the
                      page, make the changes.
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <div>
                      <p className="text-cs-blue">Kristin Watson</p>
                      <p className="text-[#848383] text-sm">March 14, 2021</p>
                    </div>
                    <div>
                      <button className="bg-cs-blue text-white px-6 py-2 rounded-lg">
                        Disable
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Dialog
            dismiss={{ outsidePress: false }}
            open={approveDialog}
            handler={() => setApproveDialog(!approveDialog)}
          >
            <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
              <div className="w-[300px] flex flex-col items-center text-center px-4 gap-4">
                <p className="font-bold">Approve</p>
                <p className="text-sm">
                  Are you sure that you want to Approve this Product?
                </p>
                <div className="flex gap-4">
                  <button
                    disabled={approveLoading}
                    onClick={() => approveProduct()}
                    className="text-white bg-cs-blue rounded-lg px-6 py-1"
                  >
                    {approveLoading ? (
                      <ImSpinner2 className="w-6 h-6 animate-spin" />
                    ) : (
                      "Yes"
                    )}
                  </button>
                  <button
                    disabled={approveLoading}
                    onClick={() => setApproveDialog(false)}
                    className="border-cs-blue border-solid border-[1px] rounded-lg px-6 py-1"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            // dismiss={{ outsidePress: false }}
            open={declineDialog}
            handler={() => setDeclineDialog(!declineDialog)}
          >
            <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
              <div className="w-[300px] flex flex-col items-center text-center px-4 gap-4">
                <p className="font-bold">Decline</p>
                <p className="text-sm">
                  What is the reason(s) for the decline?
                </p>
                <textarea
                  rows={7}
                  value={declineReason}
                  onChange={(e) => setDeclineReason(e.target.value)}
                  placeholder="Reason for Suspending Account"
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded placeholder:text-center ps-3 p-1"
                />
                <div className="flex gap-4">
                  <button
                    disabled={declineLoading}
                    onClick={() => declineProduct()}
                    className="text-white bg-cs-blue rounded-lg px-6 py-1"
                  >
                    {declineLoading ? (
                      <ImSpinner2 className="w-6 h-6 animate-spin" />
                    ) : (
                      "Okay"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
