import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Vendor } from "../../interfaces";
import axios from "axios";
import { Dialog } from "@material-tailwind/react";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";

const VendorDetails = () => {
  const data: Vendor = useLocation().state;
  const navigate = useNavigate();
  const [suspendModal, setSuspendModal] = useState(false);
  const [confirmSuspend, setConfirmSuspend] = useState(false);
  const [suspendReason, setSuspendReason] = useState("");
  const [reasonDialog, setReasonDialog] = useState(false);
  const [suspendLoading, setSuspendLoading] = useState(false);
  const isSusOrDec =
    data?.status === "suspended" || data?.status === "declined";

  const [VCountryState, setVCountryState] = useState<{
    country: string | undefined;
    state: string | undefined;
  }>({
    country: "",
    state: "",
  });
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoading, setdeleteLoadng] = useState(false)
  const [niche, setNiche] = useState({ niche: "", subNiche: "" });

  useEffect(() => {
    if (data) {
        try{
            
            axios.get("/api/web/countries").then((res) => {
              //   setCountries((prev) => (prev = res.data.data));
              setVCountryState(
                (prev) =>
                  (prev = {
                    ...prev,
                    country: res.data.data.find(
                      (c: { id: number; name: string }) =>
                        c.id === data.country_id
                    )?.name,
                  })
              );
            });
            axios.get("/api/web/states").then((res) => {
              //   setStates((prev) => (prev = res.data.data));
              setVCountryState(
                (prev) =>
                  (prev = {
                    ...prev,
                    state: res.data.data.find(
                      (c: { id: number; name: string }) =>
                        c.id === data.state_id
                    )?.name,
                  })
              );
            });
            if(data.niche_id){
                axios.get(`/api/web/niches/${data.niche_id}`).then((res) => {
                // console.log(res.data.data)
                setNiche(
                    (prev) =>
                    (prev = {
                        niche: res.data.data.name,
                        subNiche: res.data.data.sub_niches.find(
                        (n: { id: number; name: string }) => n.id === data.sub_niche_id
                        ).name,
                    })
                );
                });
            }
        } catch(err){
            console.log(err)
        }
    } else {
      navigate("/vendor");
    }
  }, []);

  const continueSuspend = () => {
    if (!suspendReason) {
      toast("Reason for suspend is required!", { type: toast.TYPE.ERROR });
      return;
    }
    setSuspendModal(false);
    setConfirmSuspend(true);
  };
  const toggleSuspendAccount = () => {
    setSuspendLoading(true);
    axios
      .post(`/api/admin/vendor/toggle-suspense/${data.id}`, {
        reason: suspendReason,
      })
      .then((res) => {
        console.log(res.data);
        setSuspendLoading(false);
        setSuspendReason("");
        toast("Vendor suspended", { type: toast.TYPE.SUCCESS });
        navigate("/vendor");
      })
      .catch((err) => {
        console.log(err);
        setSuspendLoading(false);
        toast("An error occurred try again!", { type: toast.TYPE.ERROR });
      });
  };

  const deleteAccount = () => {
    console.log("Deleting Account")
    setdeleteLoadng(true)
    axios.delete(`/api/admin/vendors/${data.id}`).then(res=>{
        console.log(res.data)
        setdeleteLoadng(false)
        setDeleteModal(false)
        toast(`Vendor ${data.id} deleted Successfully!`, { type: toast.TYPE.SUCCESS });
        navigate("/vendor")
    }).catch(err=>{
        console.log(err)
        setdeleteLoadng(false)
        toast("An error occurred try again!", { type: toast.TYPE.ERROR });
    });
  }

  if (data?.status === "pending" || !data) {
    navigate("/vendor");
    return <></>;
  }

  return (
    <div>
      <Header title="Vendor" />
      <div className="px-6 sm:px-11 pb-11 ">
        <div>
          <p className="font-bold text-xl py-6">Vendor details</p>
          <div className="pb-4 flex flex-col sm:flex-row gap-y-2 gap-x-8 text-cs-blue font-bold underline">
            <p className={`cursor-pointer ${isSusOrDec ? "opacity-50" : ""}`}>
              Reviews
            </p>
            <p className={`cursor-pointer ${isSusOrDec ? "opacity-50" : ""}`}>
              Refunds
            </p>
            <p className={`cursor-pointer ${isSusOrDec ? "opacity-50" : ""}`}>
              Returns
            </p>
            <p
              onClick={() =>
                !isSusOrDec && navigate(`analysis`, { state: data })
              }
              className={`cursor-pointer ${isSusOrDec ? "opacity-50" : ""}`}
            >
              Product Analysis
            </p>
            <p
              onClick={() => navigate(`docs`, { state: data })}
              className="cursor-pointer"
            >
              View Documents
            </p>
          </div>
          <div className="p-4 sm:p-7 lg:p-11 w-full min-h-[50vh] rounded-xl card-shadow">
            <div className="grid sm:grid-cols-2 gap-4">
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Full Name:</p>
                <p>{data.full_name}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Email:</p>
                <p>{data.email}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Status</p>
                <p
                  onClick={() => isSusOrDec && setReasonDialog(true)}
                  className={
                    data.status === "approved"
                      ? "text-[#02A655]"
                      : data.status === "pending"
                      ? "text-[#FD7C04]"
                      : "text-[#C70303] underline cursor-pointer"
                  }
                >
                  {data.status}
                </p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Phone Number:</p>
                <p>{data.phone_no}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Description:</p>
                <p>{data.description}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Account Type:</p>
                <p>{data.account_type}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Business Name:</p>
                <p>{data.business_name}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Business Registered?:</p>
                <p>{data.is_business_registered}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Registeration Code:</p>
                <p>{data.registration_code}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Niche</p>
                <p>{niche.niche}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Sub Niche</p>
                <p>{niche.subNiche}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Reason</p>
                <p>{data.reason}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Bank Name</p>
                <p>{data.bank_name}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Bank Account Number</p>
                <p>{data.bank_account_no}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Country</p>
                <p>{VCountryState.country}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">State</p>
                <p>{VCountryState.state}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Address</p>
                <p>{data.address}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Referral Type</p>
                <p>{data.referrer_type}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Referral Bonus</p>
                <p>{data.referral_bonus}</p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Address</p>
                <p>{data.address}</p>
              </div>

              <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                <p className="font-bold">Date Registered</p>
                <p>{new Date(data.created_at).toLocaleDateString()}</p>
              </div>
            </div>
            <div className="min-w-[101px] py-8 pt-16 flex justify-center gap-6">
              <button
                className="bg-cs-blue text-white px-4 py-2 rounded-lg"
                onClick={() =>
                  data.status === "approved"
                    ? setSuspendModal(true)
                    : data.status === "suspended"
                    ? toggleSuspendAccount()
                    : navigate(`/vendor/info/${data.id}`, { state: data })
                }
              >
                {suspendLoading ? (
                  <ImSpinner2 className="w-6 h-6 animate-spin" />
                ) : data.status === "approved" ? (
                  "Suspend"
                ) : data.status === "suspended" ? (
                  "Unsuspend"
                ) : (
                  "Review Details"
                )}
              </button>
              <button
                onClick={() => setDeleteModal(true)}
                className="min-w-[101px] border-2 border-solid border-cs-blue px-4 py-2 rounded-lg"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <Dialog
          open={suspendModal}
          handler={() => setSuspendModal(!suspendModal)}
        >
          <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
            <div className="w-[300px] sm:w-[400px] flex flex-col items-center text-center px-4 gap-4">
              <p className="font-bold">Reason For Suspending Account</p>
              <textarea
                rows={7}
                value={suspendReason}
                onChange={(e) => setSuspendReason(e.target.value)}
                placeholder="Reason for Suspending Account"
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded placeholder:text-center ps-3 p-1"
              />
              <button
                onClick={() => continueSuspend()}
                className="bg-cs-blue text-white rounded-lg px-6 py-1"
              >
                Continue
              </button>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={confirmSuspend}
          handler={() => setConfirmSuspend(!confirmSuspend)}
        >
          <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
            <div className="w-[300px] flex flex-col items-center text-center px-4 gap-4">
              <p className="font-bold">Suspend</p>
              <p className="text-sm">
                Are you sure that you want to suspend this?
              </p>
              <div className="flex gap-4">
                <button
                  onClick={() => toggleSuspendAccount()}
                  className="bg-cs-blue text-white rounded-lg px-6 py-1"
                >
                  {suspendLoading ? (
                    <ImSpinner2 className="w-6 h-6 animate-spin" />
                  ) : (
                    "Yes"
                  )}
                </button>
                <button
                  onClick={() => setConfirmSuspend(false)}
                  className="border-cs-blue border-solid border-[1px] rounded-lg px-6 py-1"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog dismiss={{outsidePress:false}} open={deleteModal} handler={() => setDeleteModal(!deleteModal)}>
          <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
            <div className="w-[300px] flex flex-col items-center text-center px-4 gap-4">
              <p className="font-bold text-red-500">Delete</p>
              <p className="text-sm">
                Are you sure that you want to Delete this Account?
              </p>
              <div className="flex gap-4">
                <button
                  disabled={deleteLoading}
                  onClick={() => deleteAccount()}
                  className="bg-red-500 text-white rounded-lg px-6 py-1"
                >
                  {deleteLoading ? (
                    <ImSpinner2 className="w-6 h-6 animate-spin" />
                  ) : (
                    "Yes"
                  )}
                </button>
                <button
                  disabled={deleteLoading}
                  onClick={() => setDeleteModal(false)}
                  className="border-cs-blue border-solid border-[1px] rounded-lg px-6 py-1"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={reasonDialog}
          handler={() => setReasonDialog(!reasonDialog)}
        >
          <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
            <div className="min-w-[300px] flex flex-col items-center text-center px-4 gap-4">
              <p className="font-bold">Reason</p>
              <p className="text-sm">{data.reason}</p>
              <div className="flex gap-4">
                <button
                  onClick={() => setReasonDialog(false)}
                  className="bg-cs-blue text-white rounded-lg px-6 py-1"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default VendorDetails;
