import React from "react";
import { Rating } from "@material-tailwind/react";
import { BiSolidStar } from "react-icons/bi";

const ReviewCard = () => {
  return (
    <div className="h-[320px] w-full text-sm p-3 rounded-3xl card-shadow">
      <div className="flex justify-start md:justify-center">
        <img className="max-h-[150px]" src="/Frame32.png" alt="" />
      </div>
      <div className="pt-2 font-bold">
        <p className="">MAM Feed & Soothe Essen....</p>
        <p className="">Soothe Essential</p>
      </div>
      <div>
        <Rating
          className="flex"
          ratedIcon={<BiSolidStar className="text-[#F7CE6C]" />}
          unratedIcon={<BiSolidStar className="text-[#B0BABF]" />}
          unratedColor="gray"
          value={3}
          readonly
        />
        <p className="text-blue text-base font-bold pt-2">$49</p>
      </div>
    </div>
  );
};

export default ReviewCard;
