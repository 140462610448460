import React from 'react'
import ContentLoader from "react-content-loader";

const TableLoader = () => {
  return (
    <ContentLoader backgroundColor="#CBD0D4" viewBox="0 0 380 80">
      <rect x="80" y="40" rx="3" ry="3" width="600" height="80" />
    </ContentLoader>
  )
}

export default TableLoader