import React from "react";
import Header from "../../../components/Header/Header";
import { GiWallet } from "react-icons/gi";
import { HiCube } from "react-icons/hi";
import { MdOutlineShoppingBasket } from "react-icons/md";
import { IoBasket, IoSparklesOutline } from "react-icons/io5";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { RxDotFilled } from "react-icons/rx";

const VendorProdAnalysis = () => {
  return (
    <div>
      <Header title="Vendor" />
      <div className="px-6 sm:px-11 pb-11 ">
        <div>
          <p className="font-bold text-xl py-6">Vendor Product Analysis</p>
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 pb-6 gap-4">
              <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
                <HiCube className="h-6 w-6 text-cs-blue" />
                <p className="text-sm">Total Product</p>
                <p className="text-cs-blue font-bold">0</p>
              </div>
              <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
                <IoBasket className="h-6 w-6 text-cs-blue" />
                <p className="text-sm">Total Sold</p>
                <p className="text-cs-blue font-bold">0</p>
              </div>
              <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
                <MdOutlineShoppingBasket className="h-6 w-6 text-cs-blue" />
                <p className="text-sm">Total Unsold</p>
                <p className="text-cs-blue font-bold">0</p>
              </div>
              <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
                <IoSparklesOutline className="h-6 w-6 text-cs-blue" />
                <p className="text-sm">Total Rating</p>
                <p className="text-cs-blue font-bold">0</p>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <div className="h-[320px] rounded-[10px] card-shadow">
                <div className="p-2 grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="w-[100%] h-[300px] my-auto">
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={chartData}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={90}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {/* {chartData.transactions.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))} */}
                          {/* <Cell fill="#F9B104" /> */}
                          <Cell fill="#2C9D04" />
                          <Cell fill="#CE0303" />
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="my-auto">
                    {/* <p>
                      <RxDotFilled className="inline w-[30px] h-[30px]  text-[#F9B104]" />
                      Total Transactions
                    </p> */}
                    {/* <div>Standard</div> */}
                    <div className="text-sm">
                      <RxDotFilled className="inline w-[30px] h-[30px]  text-[#2C9D04]" />
                      Approved Transactions
                    </div>
                    <div className="text-sm">
                      <RxDotFilled className="inline w-[30px] h-[30px]  text-[#CE0303]" />
                      Pending Transactions
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorProdAnalysis;

const chartData = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
];
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};