import { createSlice } from "@reduxjs/toolkit";



export const sideBarSlice = createSlice({
    name:"sidebar",
    initialState:{
        currentState:{
            open: true
        }
    },
    reducers:{
        changeSideBarState:(state, action) => {
            state.currentState.open = action.payload
        },
    },
});

export const {changeSideBarState} = sideBarSlice.actions;

export const selectSideBarState = (state) => state.sidebar.currentState.open;

export default sideBarSlice.reducer;