import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import userReducer from '../features/auth/userSlice';
import sideBarReducer from '../features/sidebarAction/sideBarSlice'

export const store = configureStore({
    reducer:{
        user: userReducer,
        sidebar: sideBarReducer,
    }
})


export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;