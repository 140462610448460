import React, { useState } from 'react'
import Header from '../../components/Header/Header'
import { useLocation } from 'react-router-dom';
import { BuyerInterface } from "../../interfaces";
import { ImSpinner2 } from 'react-icons/im';
import { Dialog } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';

const BuyerHistory = () => {
    const navigate = useNavigate()
    const data: BuyerInterface = useLocation().state
    const [suspendLoading, setSuspendLoading] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [suspendReason, setSuspendReason] = useState("")
    const [confirmSuspend, setConfirmSuspend] = useState(false);
    const [deleteLoading, setDeleteLoadng] = useState(false)
    const [reasonDialog, setReasonDialog] = useState(false);

    const continueSuspend = () => {
        setSuspendModal(false)
        setConfirmSuspend(true)
    }
    const suspendAccount = () => {

    }
    const deleteAccount = () => {

    }
if (!data) {
  navigate("/buyer");
  return <></>;
}

  return (
    <div>
      <Header title="Buyer" />
      <div className="px-6 sm:px-11 pb-11">
        <div>
          <div>
            <p className="font-bold text-xl py-6">Buyer</p>
            <div className="pb-4 flex flex-col sm:flex-row gap-y-2 gap-x-8 text-cs-blue font-bold underline">
              <p className={`cursor-pointer`}>
                Reviews
              </p>
              <p className={`cursor-pointer`}>
                Refunds
              </p>
              <p className={`cursor-pointer`}>
                Returns
              </p>
            </div>
            <div className="p-4 sm:p-7 lg:p-11 w-full min-h-[50vh] rounded-xl card-shadow">
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Full Name:</p>
                  <p>{data.full_name}</p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Orders:</p>
                  <p>{data.orders_count}</p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Email:</p>
                  <p>{data.email}</p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Pending Orders:</p>
                  <p>{data.pending_orders_count}</p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Status</p>
                  <p
                  >
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Phone Number:</p>
                  <p>{data.phone_no}</p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Referral Type</p>
                  <p>{data.referrer_type}</p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Referral Bonus</p>
                  <p>{data.referral_bonus}</p>
                </div>

                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Date Registered</p>
                  <p>{new Date(data.created_at).toLocaleDateString()}</p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between sm:gap-4">
                  <p className="font-bold">Phone Number Verification</p>
                  <p>{new Date(data.phone_no_verified_at).toLocaleDateString()}</p>
                </div>
              </div>
              <div className="min-w-[101px] py-8 pt-16 flex justify-center gap-6">
                <button
                    onClick={()=>setSuspendModal(true)}
                  className="bg-cs-blue text-white px-4 py-2 rounded-lg"
                >
                  {suspendLoading ? (
                    <ImSpinner2 className="w-6 h-6 animate-spin" />
                  ) : 
                    "Suspend"}
                </button>
                <button
                  onClick={() => setDeleteModal(true)}
                  className="min-w-[101px] border-2 border-solid border-cs-blue px-4 py-2 rounded-lg"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
          <Dialog
            open={suspendModal}
            handler={() => setSuspendModal(!suspendModal)}
          >
            <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
              <div className="w-[300px] sm:w-[400px] flex flex-col items-center text-center px-4 gap-4">
                <p className="font-bold">Reason For Suspending Account</p>
                <textarea
                  rows={7}
                  value={suspendReason}
                  onChange={(e) => setSuspendReason(e.target.value)}
                  placeholder="Reason for Suspending Account"
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded placeholder:text-center ps-3 p-1"
                />
                <button
                  onClick={() => continueSuspend()}
                  className="bg-cs-blue text-white rounded-lg px-6 py-1"
                >
                  Continue
                </button>
              </div>
            </div>
          </Dialog>
          <Dialog
            open={confirmSuspend}
            handler={() => setConfirmSuspend(!confirmSuspend)}
          >
            <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
              <div className="w-[300px] flex flex-col items-center text-center px-4 gap-4">
                <p className="font-bold">Suspend</p>
                <p className="text-sm">
                  Are you sure that you want to suspend this?
                </p>
                <div className="flex gap-4">
                  <button
                    onClick={() => suspendAccount()}
                    className="bg-cs-blue text-white rounded-lg px-6 py-1"
                  >
                    {suspendLoading ? (
                      <ImSpinner2 className="w-6 h-6 animate-spin" />
                    ) : (
                      "Yes"
                    )}
                  </button>
                  <button
                    onClick={() => setConfirmSuspend(false)}
                    className="border-cs-blue border-solid border-[1px] rounded-lg px-6 py-1"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            dismiss={{ outsidePress: false }}
            open={deleteModal}
            handler={() => setDeleteModal(!deleteModal)}
          >
            <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
              <div className="w-[300px] flex flex-col items-center text-center px-4 gap-4">
                <p className="font-bold text-red-500">Delete</p>
                <p className="text-sm">
                  Are you sure that you want to Delete this Account?
                </p>
                <div className="flex gap-4">
                  <button
                    disabled={deleteLoading}
                    onClick={() => deleteAccount()}
                    className="bg-red-500 text-white rounded-lg px-6 py-1"
                  >
                    {deleteLoading ? (
                      <ImSpinner2 className="w-6 h-6 animate-spin" />
                    ) : (
                      "Yes"
                    )}
                  </button>
                  <button
                    disabled={deleteLoading}
                    onClick={() => setDeleteModal(false)}
                    className="border-cs-blue border-solid border-[1px] rounded-lg px-6 py-1"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            open={reasonDialog}
            handler={() => setReasonDialog(!reasonDialog)}
          >
            <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
              <div className="min-w-[300px] flex flex-col items-center text-center px-4 gap-4">
                <p className="font-bold">Reason</p>
                <p className="text-sm">The reason</p>
                <div className="flex gap-4">
                  <button
                    onClick={() => setReasonDialog(false)}
                    className="bg-cs-blue text-white rounded-lg px-6 py-1"
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default BuyerHistory