import React, { useState, useEffect } from "react";
import axios from "axios";
import { Outlet, useNavigate } from "react-router-dom";
import { ImSpinner6 } from "react-icons/im";
import { useAppDispatch } from "../../app/store";
import { setAppUser } from "../../features/auth/userSlice";

const Protected = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let appUserString = localStorage.getItem("appUser");
    let appUser = appUserString ? JSON.parse(appUserString) : null;
    if (!appUser) {
      navigate("login");
      localStorage.clear();
    }
    dispatch(setAppUser(appUser));
    try {
      axios
        .get("/api/admin/products/stats/all")
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          navigate("login");
        });
    } catch (err) {
      navigate("login");
    }
  }, []);

  if (loading) {
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center">
        <ImSpinner6 className="text-cs-blue w-[50px] h-[50px] animate-spin" />
      </div>
    );
  }

  return <Outlet />;
};

export default Protected;
