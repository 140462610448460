import './Header.css'
import { useSelector } from 'react-redux'
import { selectUser } from '../../features/auth/userSlice'
import { useDispatch } from 'react-redux'
import { changeSideBarState } from '../../features/sidebarAction/sideBarSlice'
import { selectSideBarState } from '../../features/sidebarAction/sideBarSlice'
import { GiHamburgerMenu } from "react-icons/gi";

const Header = (props: {title: string}) => {
  const {name} = useSelector(selectUser)
  const sidebarState = useSelector(selectSideBarState)
  const dispatch = useDispatch();

  return (
    <div className="header">
      <div className="ps-3 flex items-center">
        <p
          className="pr-2 cursor-pointer"
          onClick={() => dispatch(changeSideBarState(!sidebarState))}
        >
          <GiHamburgerMenu className="font-bold text-white" />
        </p>
        <p className="text-white text-xl font-bold">{props.title}</p>
      </div>
      <div className="profile-tab me-3 flex gap-2 p-2">
        {/* <div className="h-[30px] w-[30px]">
          <img
            src="/aire.jpg"
            className="h-[100%] w-[100%] rounded-full"
            alt=""
          />
        </div> */}
        <p className="m-auto text-sm text-cs-blue">{name}</p>
      </div>
    </div>
  );
}

export default Header