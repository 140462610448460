import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import TableLoader from "../../components/ContentLoaders/TableLoader";
import axios from "axios";
import { BiRightArrow, BiLeftArrow, BiSearch } from "react-icons/bi";
import { MoonLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { BuyerInterface } from "../../interfaces";

const Buyer = () => {
  const navigate = useNavigate()
  

  const [dataLoading, setDataLoading] = useState(true);
  const [buyers, setBuyers] = useState<BuyerInterface[]>([]);
  const [links, setLinks] = useState<{
    prev: string | null;
    next: string | null;
  }>({
    prev: null,
    next: null,
  });

  const [nextLoading, setNextLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const getBuyers = () => {
    axios
      .get("/api/admin/customers")
      .then((res) => {
        setBuyers((prev) => (prev = res.data.data.data));
        setCurrentPage(res.data.data.current_page);
        setLinks(
          (state) =>
            (state = {
              prev: res.data.data.prev_page_url,
              next: res.data.data.next_page_url,
            })
        );
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getBuyers();
  }, []);

  const getNextPage = (next: string | null) => {
    if (next) {
      setNextLoading(true);
      axios
        .get(next)
        .then((res) => {
          setNextLoading(false);
          setCurrentPage(res.data.data.current_page);
          setBuyers((prev) => (prev = res.data.data.data));
          setLinks(
            (state) =>
              (state = {
                prev: res.data.data.prev_page_url,
                next: res.data.data.next_page_url,
              })
          );
        })
        .catch((err) => {
          setNextLoading(false);
          console.log(err);
        });
    }
  };
  const getPrevPage = (prev: string | null) => {
    if (prev) {
      setNextLoading(true);
      axios
        .get(prev)
        .then((res) => {
          setNextLoading(false);
          setCurrentPage(res.data.data.current_page);
          setBuyers((state) => (state = res.data.data.data));
          setLinks(
            (state) =>
              (state = {
                prev: res.data.data.prev_page_url,
                next: res.data.data.next_page_url,
              })
          );
        })
        .catch((err) => {
          setNextLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Header title="Buyer" />
      <div className="p-4">
        <div className="flex flex-col sm:flex-row justify-between items-center pb-4">
          <p className="font-bold text-xl mb-0">All Buyers</p>
          <div className="relative inline-block">
            <input
              className="sm:w-[250px] md:w-[300px] lg:w-[390px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 py-1 pe-6 placeholder:text-center"
              type="text"
              placeholder="search"
            />
            <div className="absolute right-0 top-0 h-full w-[10%] flex justify-center items-center">
              <BiSearch className="hover:cursor-pointer" />
            </div>
          </div>
        </div>
        <div className="p-4 shadow-lg rounded border-solid border-[1px] border-[#ABB3B7]">
          <div className="overflow-auto lg:overflow-visible ">
            <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
              <thead className="bg-[#4082E6]">
                <tr className="text-white text-sm">
                  <th className="p-2">Id</th>
                  <th className="p-2">Name</th>
                  <th className="p-2">Phone</th>
                  <th className="p-2">History</th>
                  <th className="p-2">Orders</th>
                  <th className="p-2">Pending Orders</th>
                  <th className="p-2">Reviews</th>
                  <th className="p-2">Date</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading
                  ? Array(6)
                      .fill(1)
                      .map((a, row) => (
                        <tr key={row} className="bg-[#fff] table-row-shadow">
                          {Array(8)
                            .fill(1)
                            .map((a, col) => (
                              <td key={col} className="">
                                <div className="max-w-[80px] mx-auto">
                                  <TableLoader />
                                </div>
                              </td>
                            ))}
                        </tr>
                      ))
                  : null}
                {buyers &&
                  buyers.map((buyer, idx) => {
                    return (
                      <tr key={idx} className="bg-[#fff] shadow-md table-row-shadow">
                        <td className="p-2">{buyer.id}</td>
                        <td className="p-2">{buyer.full_name}</td>
                        <td className="p-2">{buyer.phone_no}</td>
                        <td onClick={()=>navigate(`history/${buyer.id}`, {state: buyer})} className="cursor-pointer underline text-[#4082E6]">
                          View History
                        </td>
                        <td className="p-2">0</td>
                        <td className="p-2">0</td>
                        <td className="p-2">0</td>
                        <td className="p-2">
                          {new Date(buyer.created_at).toDateString()}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="bg-white w-full h-[30px] rounded-lg flex items-center justify-center gap-6">
              <div
                onClick={() => getPrevPage(links.prev)}
                className={`${
                  links.prev
                    ? "hover:cursor-pointer text-gray-400"
                    : "hover:cursor-not-allowed"
                }`}
              >
                <BiLeftArrow
                  className={`${links.prev ? "text-black" : "text-gray-400"}`}
                />
              </div>

              <div>
                {nextLoading ? (
                  <div className=""><MoonLoader size={20} /></div>
                  
                ) : (
                  <p className="text-sm mb-0">
                    Page:{" "}
                    <span className="p-1 border-solid border-[1px] border-gray-400 rounded">
                      {currentPage}
                    </span>
                  </p>
                )}
              </div>

              <div
                onClick={() => getNextPage(links.next)}
                className={`${
                  links.next
                    ? "hover:cursor-pointer text-gray-400"
                    : "hover:cursor-not-allowed"
                }`}
              >
                <BiRightArrow
                  className={`${links.next ? "text-black" : "text-gray-400"}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buyer;
