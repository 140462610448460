import Dashboard from "../pages/dashboard/Dashboard";
import Product from "../pages/product/Product";
import Vendor from "../pages/vendor/Vendor";
import Buyer from "../pages/buyer/Buyer";
import Reviews from "../pages/reviews/Reviews";
import Subscription from "../pages/subscription/Subscription";
import Settings from "../pages/settings/Settings";
// import Preferences from "../pages/preferences/Preferences";
import { Route } from "../interfaces";
import VendorInfo from "../pages/vendor/VendorInfo";
import VendorDetails from "../pages/vendor/VendorDetails";
import Vendordocs from "../pages/vendor/pages/Vendordocs";
import VendorProdAnalysis from "../pages/vendor/pages/VendorProdAnalysis";
import ProductInfo from "../pages/product/ProductInfo";
import BuyerHistory from "../pages/buyer/BuyerHistory";

const AdminRoutes: Route[] = [
    {
        path: 'dashboard',
        element: Dashboard,
    },
    {
        path: 'product',
        element: Product,
        subPages:[
            {
                path: "info/:id",
                element: ProductInfo
            },
        ]
    },
    {
        path: 'vendor',
        element: Vendor,
        subPages: [
            {
                path: "info/:id",
                element: VendorInfo
            },
            {
                path: "details/:id",
                element: VendorDetails
            },
            {
                path: "details/:id/docs",
                element: Vendordocs
            },
            {
                path: "details/:id/analysis",
                element: VendorProdAnalysis
            },
        ]
    },
    {
        path: 'buyer',
        element: Buyer,
        subPages: [
            {
                path: "history/:id",
                element: BuyerHistory
            },
        ],
    },
    {
        path: 'reviews',
        element: Reviews
    },
    {
        path: 'subscription',
        element: Subscription
    },
    
    // {
    //     path: 'preferences',
    //     element: Preferences
    // },
    {
        path: 'settings',
        element: Settings
    },
    {
        path: 'help-center',
        element: Settings
    },
    {
        path: 'admin',
        element: Settings
    },
]

export default AdminRoutes