import React, {useEffect} from 'react'
import Header from '../../../components/Header/Header'
import { useLocation } from 'react-router-dom';
import { Vendor } from '../../../interfaces';

const Vendordocs = () => {
    const data: Vendor = useLocation().state

    useEffect(()=>{
        console.log(data)
    },[])

  return (
    <div>
      <Header title="Vendor" />
      <div className="px-6 sm:px-11 pb-11 ">
        <div>
          <p className="font-bold text-xl py-6">Vendor Documents</p>
          <div className='grid lg:grid-cols-2 gap-4'>
            {data.media.map((m,idx)=>{
                return (
                  <div className="relative inline-block">
                    <div className="h-[250px] min-w-[200px] sm:w-[300px] md:w-[300px] lg:w-[350px] xl:w-[450px] border-solid border-[1px] border-[#ABB3B7] rounded-lg">
                      {data.media[idx]?.mime_type === "application/pdf" ? (
                        <iframe
                          src={`https://docs.google.com/gview?url=${data.media[idx]?.original_url}&embedded=true`}
                          title="busDoc-pdf-viewer"
                          className="w-full h-full"
                        ></iframe>
                      ) : (
                        <img
                          className="h-full mx-auto"
                          src={data.media[idx]?.original_url}
                          alt=""
                        />
                      )}
                    </div>
                    <div>
                        <p><span className='font-bold'>type: </span>{m.collection_name.split("_").join(" ")}</p>
                        <p><span className='font-bold'>name: </span>{m.name}</p>
                    </div>
                  </div>
                );
            })}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vendordocs