import React from "react";
import Header from "../../components/Header/Header";
import ReviewCard from "./ReviewCard";
import { BiSearch } from "react-icons/bi";

const Reviews = () => {
  return (
    <div>
      <Header title="Reviews" />
      <div className="p-4">
        <div className="flex justify-between">
          <p className="text-blue fw-bold m-0">View All</p>
            <div className="relative inline-block">
              <input
                className="sm:w-[250px] md:w-[300px] lg:w-[390px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 py-1 pe-6 placeholder:text-center"
                type="text"
                placeholder="search"
              />
              <div className="absolute right-0 top-0 h-full w-[10%] flex justify-center items-center">
                <BiSearch className="hover:cursor-pointer" />
              </div>
            </div>
        </div>
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
