import React from "react";
import Header from "../../components/Header/Header";
import { BiSolidWallet, BiSolidTime, BiSolidNavigation } from "react-icons/bi";
import { GiWallet } from "react-icons/gi";
import { IoStatsChart } from "react-icons/io5";
import { IoBasket } from "react-icons/io5";
import { FaSquarespace } from "react-icons/fa";
import { HiShoppingCart } from "react-icons/hi";
import { RxDotFilled } from "react-icons/rx";
import {
  HiClipboardDocumentCheck,
  HiMiniClipboardDocumentList,
  HiMiniDocumentText,
} from "react-icons/hi2";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const Dashboard = () => {
  return (
    <div>
      <Header title="Dashboard" />
      <div className="px-6 sm:px-11 pt-7 pb-11 ">
        <p className="font-bold text-xl pb-4">Overview</p>
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 pb-6 gap-4">
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <BiSolidWallet className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Balance</p>
              <p className="text-cs-blue font-bold">₦ 0</p>
            </div>
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <GiWallet className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Pending Balance</p>
              <p className="text-cs-blue font-bold">₦ 0</p>
            </div>
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <BiSolidTime className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Awaiting Shipment</p>
              <p className="text-cs-blue font-bold">0</p>
            </div>
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <BiSolidNavigation className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Shipped</p>
              <p className="text-cs-blue font-bold">0</p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 pb-6 gap-4">
            <div className="flex items-center px-4 sm:px-9 gap-6 py-20 card-shadow rounded-lg">
              <div>
                <IoStatsChart className="h-24 w-24 text-cs-blue" />
              </div>
              <div className="font-bold">
                <p className="text-xl text-[#848383]">Total Sales</p>
                <p className="text-2xl">0</p>
              </div>
            </div>
            <div className="flex items-center px-4 sm:px-9 gap-6 py-20 card-shadow rounded-lg">
              <div>
                <IoBasket className="h-24 w-24 text-cs-blue" />
              </div>
              <div className="font-bold">
                <p className="text-xl text-[#848383]">Total Orders</p>
                <p className="text-2xl">0</p>
              </div>
            </div>
            <div className="flex items-center px-4 sm:px-9 gap-6 py-20 card-shadow rounded-lg">
              <div>
                <FaSquarespace className="h-24 w-24 text-cs-blue" />
              </div>
              <div className="font-bold">
                <p className="text-xl text-[#848383]">Total Vendors</p>
                <p className="text-2xl">0</p>
              </div>
            </div>
            <div className="flex items-center px-4 sm:px-9 gap-6 py-20 card-shadow rounded-lg">
              <div>
                <HiShoppingCart className="h-24 w-24 text-cs-blue" />
              </div>
              <div className="font-bold">
                <p className="text-xl text-[#848383]">Total Buyers</p>
                <p className="text-2xl">0</p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pb-6 gap-4">
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <HiClipboardDocumentCheck className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Approved Registeration</p>
              <p className="text-cs-blue font-bold">0</p>
            </div>
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <HiMiniClipboardDocumentList className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Pending Registeration</p>
              <p className="text-cs-blue font-bold">0</p>
            </div>
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <HiMiniDocumentText className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Declined Registeration</p>
              <p className="text-cs-blue font-bold">0</p>
            </div>
          </div>
          <div>
            <p className="font-bold text-xl pb-6">Top Regions With Most Sales</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="h-320px rounded-[10px] card-shadow">
                <div className="p-2 flex flex-col items-center">
                  <p className="font-bold">Transactions</p>
                  <div className="w-[100%] h-[200px]">
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={chartData}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          innerRadius={30}
                          outerRadius={90}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {/* {chartData.transactions.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))} */}
                          <Cell fill="#F9B104" />
                          <Cell fill="#2C9D04" />
                          <Cell fill="#CE0303" />
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="ms-0">
                    <p>
                      <RxDotFilled className="inline w-[30px] h-[30px]  text-[#F9B104]" />
                      Total Transactions
                    </p>
                    {/* <div>Standard</div> */}
                    <div>
                      <RxDotFilled className="inline w-[30px] h-[30px]  text-[#2C9D04]" />
                      Approved Transactions
                    </div>
                    <div>
                      <RxDotFilled className="inline w-[30px] h-[30px]  text-[#CE0303]" />
                      Pending Transactions
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

const chartData = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
];
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};