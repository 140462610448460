import React from 'react'
import Header from '../../components/Header/Header'
import { IoCheckmarkCircle } from 'react-icons/io5';

const Subscription = () => {
  return (
    <div>
      <Header title="Subscription" />
      <div className="px-6 sm:px-11 py-11">
        <div>
          <div className="grid sm:grid-cols-2 gap-6">
            <div className="h-[450px] border-2 rounded-xl p-11">
              <div className="flex justify-end">
                <button className="border-cs-blue border px-8 py-2 rounded-lg">
                  Edit
                </button>
              </div>
              <div className="flex flex-col">
                <p className="font-bold pb-4">Premium</p>
                <p className="text-[#848383] text-sm pb-4">
                  <span className="text-cs-blue font-bold text-lg">$200</span> /
                  Month
                </p>
                <div className='flex flex-col gap-2 pb-11'>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />
                    30 Products
                  </p>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />
                    Advanced Reporting
                  </p>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />3
                    Featured Listing
                  </p>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />
                    Forecast
                  </p>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />
                    Listing
                  </p>
                </div>
                <div className='flex justify-center'>
                    <button className='bg-cs-blue text-white px-16 py-2 rounded-lg'>Set</button>
                </div>
              </div>
            </div>
            <div className="h-[450px] border-2 rounded-xl p-11">
              <div className="flex justify-end">
                <button className="border-cs-blue border px-8 py-2 rounded-lg">
                  Edit
                </button>
              </div>
              <div className="flex flex-col">
                <p className="font-bold pb-4">Premium</p>
                <p className="text-[#848383] text-sm pb-4">
                  <span className="text-cs-blue font-bold text-lg">$200</span> /
                  Month
                </p>
                <div className='flex flex-col gap-2 pb-11'>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />
                    30 Products
                  </p>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />
                    Advanced Reporting
                  </p>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />3
                    Featured Listing
                  </p>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />
                    Forecast
                  </p>
                  <p className="flex items-center gap-2">
                    <IoCheckmarkCircle className="w-4 h-4 text-[#63BF92]" />
                    Listing
                  </p>
                </div>
                <div className='flex justify-center'>
                    <button className='bg-cs-blue text-white px-16 py-2 rounded-lg'>Set</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription