import { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { BiSearch } from "react-icons/bi";
import {
  HiClipboardDocumentCheck,
  HiMiniClipboardDocumentList,
  HiMiniDocumentText,
} from "react-icons/hi2";
import TableLoader from "../../components/ContentLoaders/TableLoader";
import axios from "axios";
import { Product as ProductInterface, SearchData } from "./ProductInterface";
import { MoonLoader } from "react-spinners";
import {
  BiLeftArrow,
  BiRightArrow,
  BiDotsVerticalRounded,
} from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ProductAction from "./ProductAction";
import { ImSpinner2 } from "react-icons/im";
import { Dialog } from "@material-tailwind/react";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
} from "@material-tailwind/react";
import { IoClose } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";

const Product = () => {
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(true);
  const [products, setProducts] = useState<ProductInterface[]>([]);
  const [links, setLinks] = useState<{
    prev: string | null;
    next: string | null;
  }>({
    prev: null,
    next: null,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [nextLoading, setNextLoading] = useState(false);
  type productStatus = "approved" | "pending" | "declined" | undefined;
  const [filter, setFilter] = useState<{
    status: productStatus;
    search: string;
  }>({
    status: undefined,
    search: "",
  });
  const [searchResults, setSearchResults] = useState<SearchData>(
    {} as SearchData
  );
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [delProd, setDelProd] = useState(0);
  const deleteProduct = (productId: number) => {
    if (!productId) {
      return;
    }
    setDeleteLoading(true);
    axios
      .delete(`/api/admin/products/${productId}`)
      .then((res) => {
        console.log(res.data);
        toast("Product Deleted.", { type: toast.TYPE.SUCCESS });
        setDelProd(0);
        setDeleteDialog(false);
        setDeleteLoading(false);
        runSearch();
      })
      .catch((err) => {
        setDeleteDialog(false);
        setDeleteLoading(false);
        toast("An error occurred try again.", { type: toast.TYPE.ERROR });
        console.log(err);
      });
  };

  const [stat, setStat] = useState({
    approved: 0,
    declined: 0,
    pending: 0,
  });

  const getStat = () => {
    axios
      .get("/api/admin/products/stats/all")
      .then((res) => {
        setStat((prev) => (prev = res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProducts = () => {
    axios
      .get("/api/admin/products")
      .then((res) => {
        // console.log(res.data.data.data);
        setProducts((prev) => (prev = res.data.data.data));
        setLinks(
          (prev) =>
            (prev = {
              prev: res.data.data.prev_page_url,
              next: res.data.data.next_page_url,
            })
        );
        setCurrentPage((prev) => (prev = res.data.data.current_page));
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setDataLoading(false);
      });
  };
  const getPrevPage = (prev: string | null) => {
    if (prev) {
      setNextLoading(true);
      axios
        .get(prev, { params: filter })
        .then((res) => {
          setNextLoading(false);

          setProducts((prev) => (prev = res.data.data.products));
          const previous = currentPage > 1;
          if (!previous) {
            setLinks((prev) => (prev = { ...prev, prev: null }));
          }
          setCurrentPage(currentPage - 1);
        })
        .catch((err) => {
          setNextLoading(false);
          console.log(err);
        });
    }
  };

  const getNextPage = (next: string | null) => {
    if (next) {
      setNextLoading(true);
      axios
        .get(next, { params: filter })
        .then((res) => {
          setNextLoading(false);
          setProducts((prev) => (prev = res.data.data.products));
          setCurrentPage(currentPage + 1);

          const next =
            searchResults.totalProducts > currentPage * searchResults.perPage;
          if (!next) {
            setLinks((prev) => (prev = { ...prev, next: null }));
          }
        })
        .catch((err) => {
          setNextLoading(false);
          console.log(err);
        });
    }
  };

  const runSearch = (text?: string) => {
    setNextLoading(true);
    axios
      .get(`/api/admin/search/products`, { params: filter })
      .then((res) => {
        // console.log(res.data);
        setProducts((prev) => (prev = res.data.data.products));
        setSearchResults((prev) => (prev = res.data.data));
        setNextLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStatusChange = (status: productStatus) => {
    setFilter((prev) => (prev = { ...prev, status: status }));
  };

  useEffect(() => {
    const next =
      searchResults.totalProducts > currentPage * searchResults.perPage;
    const prev = currentPage > 1;
    // console.log(prev);
    if (!next) {
      setLinks((prev) => (prev = { ...prev, next: null }));
    } else {
      setLinks(
        (prev) =>
          (prev = {
            ...prev,
            next: `/api/admin/search/products?page=${currentPage + 1}`,
          })
      );
    }
    if (!prev) {
      setLinks((prev) => (prev = { ...prev, prev: null }));
    } else {
      setLinks(
        (prev) =>
          (prev = {
            ...prev,
            prev: `/api/admin/search/products?page=${currentPage - 1}`,
          })
      );
    }
  }, [products, searchResults]);

  useEffect(() => {
    setCurrentPage(1);
    runSearch();
  }, [filter]);

  useEffect(() => {
    getProducts();
    getStat();
  }, []);

  return (
    <div>
      <Header title="Product" />
      <div className="px-6 sm:px-11 pb-11">
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-center ">
            <p className="py-4 font-bold mb-0 text-xl">Overview</p>
            <div className="relative inline-block">
              <input
                className="sm:w-[250px] md:w-[300px] lg:w-[390px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 py-1 pe-6 placeholder:text-center"
                type="text"
                onChange={(e) =>
                  setFilter(
                    (prev) => (prev = { ...prev, search: e.target.value })
                  )
                }
                placeholder="search"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pb-6 gap-4">
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <HiClipboardDocumentCheck className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">New order</p>
              <p className="text-cs-blue font-bold">0</p>
            </div>
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <HiMiniClipboardDocumentList className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Out of Stock</p>
              <p className="text-cs-blue font-bold">0</p>
            </div>
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <HiMiniDocumentText className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Newly Added</p>
              <p className="text-cs-blue font-bold">0</p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pb-6 gap-4">
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <HiClipboardDocumentCheck className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Total Approved</p>
              <p className="text-cs-blue font-bold">{stat.approved}</p>
            </div>
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <HiMiniClipboardDocumentList className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Total Declined</p>
              <p className="text-cs-blue font-bold">{stat.declined}</p>
            </div>
            <div className="flex flex-col items-center gap-2 py-6 card-shadow rounded-lg">
              <HiMiniDocumentText className="h-6 w-6 text-cs-blue" />
              <p className="text-sm">Total Pending</p>
              <p className="text-cs-blue font-bold">{stat.pending}</p>
            </div>
          </div>

          <div className="pb-6">
            <div className="flex justify-between">
              <p className="font-bold">Items</p>
              <div className="text-cs-blue font-bold flex gap-4">
                <p
                  onClick={() => handleStatusChange(undefined)}
                  className={`cursor-pointer ${
                    filter.status === undefined && "underline"
                  }`}
                >
                  All
                </p>
                <p
                  onClick={() => handleStatusChange("approved")}
                  className={`cursor-pointer ${
                    filter.status === "approved" && "underline"
                  }`}
                >
                  Approved
                </p>
                <p
                  onClick={() => handleStatusChange("declined")}
                  className={`cursor-pointer ${
                    filter.status === "declined" && "underline"
                  }`}
                >
                  Declined
                </p>
                <p
                  onClick={() => handleStatusChange("pending")}
                  className={`cursor-pointer ${
                    filter.status === "pending" && "underline"
                  }`}
                >
                  Pending
                </p>
              </div>
            </div>
          </div>

          <div className="p-4 shadow-lg rounded border-solid border-[1px] border-[#ABB3B7]">
            <div className="overflow-auto lg:overflow-visible ">
              <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                <thead className="bg-cs-blue">
                  <tr className="text-white">
                    <th className="p-2">Id</th>
                    <th className="p-2">Date</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Status</th>
                    <th className="p-2">Type</th>
                    <th className="p-2">Description</th>
                    <th className="p-2">Quantity</th>
                    <th className="p-2">Price($)</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dataLoading
                    ? Array(6)
                        .fill(1)
                        .map((a, row) => (
                          <tr key={row} className="bg-[#fff]">
                            {Array(9)
                              .fill(1)
                              .map((a, col) => (
                                <td key={col} className="">
                                  <div className="max-w-[90px] mx-auto">
                                    <TableLoader />
                                  </div>
                                </td>
                              ))}
                          </tr>
                        ))
                    : null}
                  {!dataLoading &&
                    products.map((prod, idx) => {
                      return (
                        <tr key={idx} className="table-shadow">
                          <td className="p-2">{prod.id}</td>
                          <td className="p-2">
                            {new Date(prod.created_at).toLocaleDateString()}
                          </td>
                          <td className="p-2">{prod.name}</td>
                          <td
                            className={`p-2 
                              ${
                                prod.status === "approved"
                                  ? "text-[#02A655]"
                                  : prod.status === "pending"
                                  ? "text-[#FD7C04]"
                                  : "text-[#C70303]"
                              }
                                `}
                          >
                            {prod.status}
                          </td>
                          <td className="p-2">{prod.type}</td>
                          <td
                            className="p-2 text-cs-blue cursor-pointer"
                            onClick={() =>
                              navigate(`info/${prod.id}`, { state: prod })
                            }
                          >
                            View
                          </td>
                          <td className="p-2">{prod.quantity}</td>
                          <td className="p-2">{prod.price.toFixed(2)}</td>
                          <td className="p-2">
                            <Popover placement="left">
                              <PopoverHandler>
                                <Button color="white">
                                  <BiDotsVerticalRounded className="cursor-pointer" />
                                </Button>
                              </PopoverHandler>
                              <PopoverContent>
                                <div>
                                  <p className="font-bold underline pb-2">
                                    Product {prod.id}
                                  </p>
                                  <div
                                    onClick={() => {
                                      setDeleteDialog(true);
                                      setDelProd(prod.id);
                                    }}
                                    className="text-red-500 cursor-pointer flex items-center gap-1"
                                  >
                                    <MdOutlineDelete />
                                    <p>Delete</p>
                                  </div>
                                </div>
                              </PopoverContent>
                            </Popover>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="bg-white w-full h-[60px] table-shadow rounded-lg flex items-center justify-center gap-6">
                <div
                  onClick={() => getPrevPage(links.prev)}
                  className={`${
                    links.prev
                      ? "hover:cursor-pointer text-gray-400"
                      : "hover:cursor-not-allowed"
                  }`}
                >
                  <BiLeftArrow
                    className={`${links.prev ? "text-black" : "text-gray-400"}`}
                  />
                </div>

                <div>
                  {nextLoading ? (
                    <MoonLoader size={20} />
                  ) : (
                    <p className="text-sm">
                      Page:{" "}
                      <span className="p-1 border-solid border-[1px] border-gray-400 rounded">
                        {currentPage}
                      </span>
                    </p>
                  )}
                </div>

                <div
                  onClick={() => getNextPage(links.next)}
                  className={`${
                    links.next
                      ? "hover:cursor-pointer text-gray-400"
                      : "hover:cursor-not-allowed"
                  }`}
                >
                  <BiRightArrow
                    className={`${links.next ? "text-black" : "text-gray-400"}`}
                  />
                </div>
              </div>
            </div>
          </div>

          <Dialog
            dismiss={{ outsidePress: false }}
            open={deleteDialog}
            handler={() => setDeleteDialog(!deleteDialog)}
          >
            <div className="modal bg-white p-4 border border-solid border-[#B0BABF] rounded-lg">
              <div className="w-[300px] flex flex-col items-center text-center px-4 gap-4">
                <p className="font-bold">Delete</p>
                <p className="text-sm">
                  Are you sure that you want to Delete this Product?
                </p>
                <div className="flex gap-4">
                  <button
                    disabled={deleteLoading}
                    onClick={() => deleteProduct(delProd)}
                    className="text-white bg-red-500 rounded-lg px-6 py-1"
                  >
                    {deleteLoading ? (
                      <ImSpinner2 className="w-6 h-6 animate-spin" />
                    ) : (
                      "Yes"
                    )}
                  </button>
                  <button
                    disabled={deleteLoading}
                    onClick={() => setDeleteDialog(false)}
                    className="border-cs-blue border-solid border-[1px] rounded-lg px-6 py-1"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Product;
