import React, { useEffect } from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectSideBarState } from '../../features/sidebarAction/sideBarSlice';
import { changeSideBarState } from '../../features/sidebarAction/sideBarSlice';
import AdminRoutes from '../../routes/Routes';
import { IoClose } from 'react-icons/io5';
import { BiPowerOff } from 'react-icons/bi';

const Sidebar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const sideBarOpen = useSelector(selectSideBarState)
  const dispatch = useDispatch();
  const url = location.pathname

  const handleLogOut = () => {
    localStorage.clear()
    navigate("/")
  }
  const [width, setWidth] = React.useState(window.innerWidth);
  const handleResize = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  },[])
  useEffect(() => {
    if(width < 786){
      dispatch(changeSideBarState(false))
    } else{
      dispatch(changeSideBarState(true))
    }
  },[width])

  return (
    <div
      className={`bg-black overflow-auto flex-1 min-w-[232px] max-w-[323px] ${
        width < 786 ? "absolute z-50 h-[100vh]" : "relative"
      }`}
      style={{ display: `${sideBarOpen ? "" : "none"}` }}
    >
      {width < 786 && (
        <IoClose
          className="text-white pr-2 pt-2 w-6 h-6 float-right cursor-pointer"
          onClick={() => dispatch(changeSideBarState(false))}
        />
      )}
      <div className="flex justify-center py-8 list-none text-white">
        <img src="/images/logowhite.png" alt="logo" />
      </div>
      <div className="">
        <ul className="py-8 list-none text-white text-lg">
          {AdminRoutes.map((route, idx) => {
            return (
              <li
                key={idx}
                className={
                  (url.includes(route.path) ? "bg-[#529EF2]" : "") +
                  " max-w-full"
                }
              >
                <Link
                  to={route.path}
                  className="flex items-center gap-2 ps-8 py-[10px]"
                >
                  <img className='w-5 h-5' src={`/images/icons/${route.path}.svg`} alt="" />
                  {route.path.charAt(0).toUpperCase() + route.path.slice(1)}
                </Link>
              </li>
            );
          })}

          <li onClick={handleLogOut} className="max-w-full flex gap-2 items-center cursor-pointer hover:text-red-500 ps-8">
            <BiPowerOff className='w-5 h-5' />Log Out
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar