import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import MainLayout from './components/Layout/MainLayout';
import Login from './pages/auth/Login';
import AdminRoutes from './routes/Routes';
import axios from 'axios';
import Protected from './components/auth/Protected';
import { Navigate } from 'react-router-dom';
import { Fragment } from 'react';


axios.defaults.baseURL = "https://apis.smartafri.com/"
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.interceptors.request.use(function (config){
  const token = localStorage.getItem('auth_token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<Protected />}>
          <Route element={<MainLayout />}>
            {AdminRoutes.map((route, i) => {
              return (
                <Fragment key={i}>
                  <Route path={route.path} element={<route.element />} />
                  {route.subPages && route.subPages.length
                    ? route.subPages.map((sub, key) => {
                        return (
                          <Route
                            key={key}
                            path={route.path + "/" + sub.path}
                            element={<sub.element />}
                          />
                        );
                      })
                    : ""}
                </Fragment>
              );
            })}
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
