import React, { useState } from "react";
import { useAppDispatch } from "../../app/store";
import { setAppUser } from "../../features/auth/userSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ImSpinner2 } from "react-icons/im";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
    validation: "",
    loading: false,
    pageloading: true,
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginValues({ ...loginValues, validation: "", [name]: value });
  };
  const loginSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoginValues({ ...loginValues, loading: true });
    const data = {
      email: loginValues.email,
      password: loginValues.password,
    };
    axios
      .post("/api/admin/auth/login", data)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.token) {
          setLoginValues({ ...loginValues, loading: false });
          localStorage.setItem("auth_token", res.data.data.token);
          dispatch(
            setAppUser(res.data.data.admin)
          );
          navigate("/dashboard");
        } else {
          setLoginValues({
            ...loginValues,
            loading: false,
            validation: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        setLoginValues(
          (prev) =>
            (prev = {
              ...loginValues,
              loading: false,
              validation: err.response?.data?.message
                ? err.response?.data?.message
                : err.message,
            })
        );
      });
    // dispatch(login(data))
  };

  return (
    <div className="">
      <div className="w-full h-[10vh] bg-cs-blue text-white text-center flex items-center"></div>
      <div className="h-[80vh]  m-auto flex justify-center items-center">
        <div className="bg-[#fafafa] p-11 flex justify-center items-center card-shadow">
          <form className="">
            <div className="flex justify-center py-6">
              <img className="" src="/logoblue.svg" alt="" />
            </div>
            <h4 className="mb-3 font-bold text-center text-cs-blue">
              Sign in to your account
            </h4>
            {loginValues.validation ? (
              <p
                className="p-3"
                style={{
                  backgroundColor: "#CF020E",
                  color: "#fff",
                  borderRadius: "5px",
                }}
              >
                {loginValues.validation}
                <span></span>
              </p>
            ) : null}
            <div className="mb-3">
              <label className="mb-1" htmlFor="email">
                Email address
              </label>
              <input
                type="email"
                onChange={(e) => handleChange(e)}
                name="email"
                value={loginValues.email}
                required
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded placeholder:text-center ps-3 p-1"
              />
            </div>
            <div className="mb-5">
              <label className="mb-1" htmlFor="email">
                Password
              </label>
              <input
                type="password"
                onChange={(e) => handleChange(e)}
                name="password"
                value={loginValues.password}
                required
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded placeholder:text-center ps-3 p-1"
              />
            </div>
            <div className="flex">
              <button
                className="mx-auto bg-cs-blue text-white px-4 py-2 rounded-lg"
                disabled={loginValues.loading}
                onClick={(e) => loginSubmit(e)}
              >
                {loginValues.loading ? (
                  <ImSpinner2 className="w-6 h-6 animate-spin" />
                ) : (
                  "Log In"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
