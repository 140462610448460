import React, { useState } from 'react'
import Header from '../../components/Header/Header'
import { useAppSelector } from '../../app/store'
import { useLocation } from 'react-router-dom'

const Settings = () => {
  const [editable,setEditable] = useState(true)
  const user = useAppSelector(state => state.user)
  return (
    <div>
      <Header title="Settings" />
      <div className="px-6 sm:px-11 py-11">
        <div>
          <div className="flex flex-col items-center gap-2">
            <div>
              <button className="border-cs-blue border rounded-lg px-6 py-1">
                Edit
              </button>
            </div>
            <div className="w-[612px] flex flex-col gap-2">
              <div className="">
                <label htmlFor="status" className="text-sm">
                  Fullname
                </label>
                <input
                  type="text"
                  name="status"
                  value={user.name}
                  readOnly={editable}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                />
              </div>
              <div className="">
                <label htmlFor="eail" className="text-sm">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={user.email}
                  readOnly
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                />
              </div>
              <div className="">
                <label htmlFor="old_password" className="text-sm">
                  Old Password
                </label>
                <input
                  type="text"
                  name="old_password"
                  readOnly={editable}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                />
              </div>
              <div className="">
                <label htmlFor="new_password" className="text-sm">
                  New Password
                </label>
                <input
                  type="text"
                  name="new_password"
                  readOnly={editable}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                />
              </div>
              <div className="">
                <label htmlFor="confirm_new_password" className="text-sm">
                  Confirm New Password
                </label>
                <input
                  type="text"
                  name="confirm_new_password"
                  readOnly={editable}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                />
              </div>
            </div>
            <div className="pt-11">
              <button className="bg-cs-blue text-white px-16 py-2 rounded-lg">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings