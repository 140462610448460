import React from 'react'
import './style.css'
import Sidebar from '../Sidebar/Sidebar'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../../features/auth/userSlice'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

const MainLayout = () => {

  return (
    <div className='main-container'>
        <Sidebar />
        <div className='outlet'>
          <Outlet />
        </div>
        
      <ToastContainer
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={true}
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default MainLayout